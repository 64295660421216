<template>
  <div>
    <vx-card title="Credit Note">
      <operating-unit-filter @onShow="onShow" />
      <vs-tabs :color="colorx" v-model="tabs">
        <!-- <vs-tab @click="colorx = 'danger'" label="Customer Return Complete">
          <div class="con-tab-ejemplo">
            <complete :isReload="isReload" />
          </div>
        </vs-tab> -->
        <vs-tab @click="colorx = 'warning'" label="Draft">
          <div class="con-tab-ejemplo">
            <draft :isReload="isReload" />
          </div>
        </vs-tab>
        <!-- <vs-tab @click="colorx = 'warning'" label="Waiting Approval">
          <div class="con-tab-ejemplo">
            <waiting :isReload="isReload" />
          </div>
        </vs-tab>
        <vs-tab @click="colorx = 'warning'" label="Approved">
          <div class="con-tab-ejemplo">
            <approved :isReload="isReload" />
          </div>
        </vs-tab>
        <vs-tab @click="colorx = 'warning'" label="Rejected">
          <div class="con-tab-ejemplo">
            <rejected :isReload="isReload" />
          </div>
        </vs-tab> -->
        <vs-tab @click="colorx = 'warning'" label="Released">
          <div class="con-tab-ejemplo">
            <released :isReload="isReload" />
          </div>
        </vs-tab>
        <vs-tab @click="colorx = 'warning'" label="Applied">
          <div class="con-tab-ejemplo">
            <applied :isReload="isReload" />
          </div>
        </vs-tab>
        <vs-tab @click="colorx = 'warning'" label="Waiting Reversal">
          <div class="con-tab-ejemplo">
            <waitingRevers :isReload="isReload" />
          </div>
        </vs-tab>
        <vs-tab @click="colorx = 'warning'" label="Reversed">
          <div class="con-tab-ejemplo">
            <reversed :isReload="isReload" />
          </div>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </div>
</template>
<script>
import complete from "./complete.vue";
import draft from "./draft.vue";
import waiting from "./waiting_approval.vue";
import approved from "./approved.vue";
import rejected from "./rejected.vue";
import released from "./released.vue";
import applied from "./applied.vue";
import reversed from "./reversed.vue";
import waitingRevers from "./waiting-reversal.vue";
import operatingUnitFilter from "../../../../components/operatingUnitFilter/operatingUnitFilter.vue";

export default {
  components: {
    complete,
    draft,
    rejected,
    approved,
    waiting,
    released,
    applied,
    reversed,
    waitingRevers,
    "operating-unit-filter": operatingUnitFilter,
  },

  data: () => ({
    colorx: "danger",
    tabs: 0,
    isReload: false,
  }),

  methods: {
    onShow() {
      this.isReload = true;
      this.$nextTick(() => {
        this.isReload = false;
      });
    },
  },
};
</script>
